// src/components/SavingsForm.js

import React, { useState } from 'react';

const SavingsForm = ({ onCalculate }) => {
  const [formData, setFormData] = useState({
    monthlyBill: '',
    kWhUsage: '',
    financingOption: 'ppa', // Options: 'ppa', 'loan', or 'cash'
    loanTermYears: 15, // Default loan term
  });

  const [error, setError] = useState('');

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    setError('');
  };

  // Handle form submission
  const handleCalculate = () => {
    // Validation
    if (!formData.kWhUsage && !formData.monthlyBill) {
      setError(
        'Please provide either your kWh usage or your electricity bill for an accurate savings calculation.'
      );
      return;
    }

    // Calculation Logic
    const yearlyKWhUsage = formData.kWhUsage
      ? parseFloat(formData.kWhUsage) * 12
      : (parseFloat(formData.monthlyBill) * 12) / 0.3; // Assuming average rate of $0.30/kWh

    const monthlyKWhUsage = yearlyKWhUsage / 12;
    const rate = formData.monthlyBill
      ? parseFloat(formData.monthlyBill) / monthlyKWhUsage
      : 0.3; // Default rate if only kWh is provided

    const systemSizeKw = Math.ceil((yearlyKWhUsage * 1.1) / 1200);
    const numberOfPanels = Math.ceil((systemSizeKw * 1000) / 350); // Assuming 350W panels

    // Adjusted Battery Calculation
    // Batteries should cover up to 2x the system size but never exceeding 2x
    const batteryCapacityNeededKw = systemSizeKw * 2; // Up to 2x the system size
    let numberOfBatteries = Math.ceil(batteryCapacityNeededKw / 5); // Each battery is 5 kW

    let totalBatteryCapacityKw = numberOfBatteries * 5; // Total battery capacity

    // If total battery capacity exceeds 2x the system size, subtract 1 battery
    if (totalBatteryCapacityKw > batteryCapacityNeededKw) {
      numberOfBatteries = numberOfBatteries - 1;
      totalBatteryCapacityKw = numberOfBatteries * 5;
    }

    // Ensure at least 1 battery
    if (numberOfBatteries < 1) {
      numberOfBatteries = 1;
      totalBatteryCapacityKw = 5;
    }

    // Solar cost calculation
    const solarCost =
      systemSizeKw * 1000 * 4 + numberOfBatteries * 6000; // $4 per Watt for panels, $6000 per battery

    // Pass the calculated results to the parent component
    onCalculate({
      yearlyKWhUsage,
      rate,
      systemSizeKw,
      numberOfPanels,
      batteryCapacityKw: totalBatteryCapacityKw,
      numberOfBatteries,
      solarCost,
      financingOption: formData.financingOption,
      loanTermYears: parseInt(formData.loanTermYears),
    });
  };

  return (
    <div className="bg-white shadow-md p-4 w-full flex flex-col h-full">
      <h2 className="text-lg font-bold mb-4">Enter Your Details</h2>

      <div className="space-y-4 flex-grow">
        {/* Monthly Bill Field */}
        <div>
          <label className="block font-medium">Monthly Bill ($)</label>
          <input
            type="number"
            name="monthlyBill"
            value={formData.monthlyBill}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded"
            placeholder="Enter your monthly bill amount"
          />
        </div>

        {/* Monthly kWh Usage Field */}
        <div>
          <label className="block font-medium">Monthly kWh Usage</label>
          <input
            type="number"
            name="kWhUsage"
            value={formData.kWhUsage}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded"
            placeholder="Enter your monthly kWh usage"
          />
        </div>

        {/* Financing Option Field */}
        <div>
          <label className="block font-medium">Financing Option</label>
          <select
            name="financingOption"
            value={formData.financingOption}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded"
          >
            <option value="ppa">Power Purchase Agreement (PPA)</option>
            <option value="loan">Loan</option>
            <option value="cash">Cash</option>
          </select>
        </div>

        {/* Loan Term Selection */}
        {formData.financingOption === 'loan' && (
          <div>
            <label className="block font-medium">Loan Term (Years)</label>
            <select
              name="loanTermYears"
              value={formData.loanTermYears}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded"
            >
              <option value="15">15 Years</option>
              <option value="20">20 Years</option>
            </select>
          </div>
        )}

        {/* Error Message */}
        {error && <p className="text-red-500 text-sm">{error}</p>}
      </div>

      {/* Calculate Savings Button */}
      <button
        onClick={handleCalculate}
        className="w-full mt-6 bg-green-500 text-white py-2 rounded hover:bg-green-600 transition-colors"
      >
        Calculate Savings
      </button>
    </div>
  );
};

export default SavingsForm;