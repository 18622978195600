// src/components/FloatingCTA.js

import React from 'react';
import { HashLink as Link } from 'react-router-hash-link'; // Import HashLink for smooth scrolling

const FloatingCTA = () => {
  return (
    <Link
      smooth
      to="/#top" // Updated link to target the top of the homepage
      className="fixed bottom-6 right-6 bg-green-600 text-white p-4 rounded-full shadow-lg hover:bg-green-700 transition-colors z-50"
      title="Get Your Savings Report"
      aria-label="Get Your Savings Report" // Enhances accessibility
    >
      {/* Updated SVG Icon: Document/Report */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        {/* Example Document Icon */}
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 12h6m2 0a2 2 0 110 4h-1a2 2 0 110-4h1zm-10 0a2 2 0 110 4h1a2 2 0 110-4H1zM5 16v2a2 2 0 002 2h10a2 2 0 002-2v-2M5 16l2-2m0 0l2 2m-2-2v-4a2 2 0 012-2h4a2 2 0 012 2v4"
        />
      </svg>
    </Link>
  );
};

export default FloatingCTA;