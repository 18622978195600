// src/components/SavingsOverTimeGraph.js

import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const SavingsOverTimeGraph = ({ data }) => {
  if (!data || !data.datasets || data.datasets.length === 0) {
    return (
      <div className="bg-white shadow-md rounded p-4 w-full h-full flex flex-col">
        <h3 className="text-lg font-semibold mb-2">Savings Over Time</h3>
        <p>No data available</p>
      </div>
    );
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows the graph to adjust its height based on container
    plugins: {
      legend: { position: 'top' },
      title: {
        display: true,
        text: 'Monthly Utility vs Solar Bill Over 30 Years',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || '';
            const value = context.parsed.y || 0;
            return `${label}: $${value.toFixed(2)}`;
          },
        },
      },
    },
    scales: {
      x: { 
        title: { 
          display: true, 
          text: 'Year' 
        } 
      },
      y: {
        title: { 
          display: true, 
          text: 'Monthly Cost ($)' 
        },
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: Math.max(
          ...data.datasets[0].data,
          ...data.datasets[1].data
        ) * 1.1, // Adds a 10% buffer above the highest data point
      },
    },
  };

  return (
    <div className="bg-white shadow-md rounded p-4 w-full h-full flex flex-col">
      <h3 className="text-lg font-semibold mb-2">Savings Over Time</h3>
      <div className="flex-1">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default SavingsOverTimeGraph;