// src/pages/NotFoundPage.js

import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // Ensure HelmetProvider is wrapping your app

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>404 - Page Not Found | California Solar Relief</title>
        <meta name="description" content="The page you are looking for does not exist." />
      </Helmet>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
        <h1 className="text-6xl font-bold text-gray-800 dark:text-gray-200 mb-4">404</h1>
        <h2 className="text-3xl font-semibold text-gray-700 dark:text-gray-300 mb-6">
          Page Not Found
        </h2>
        <p className="text-gray-600 dark:text-gray-400 mb-8">
          Oops! The page you're looking for doesn't exist.
        </p>
        <Link
          to="/"
          className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition-colors"
        >
          Go Back Home
        </Link>
      </div>
    </>
  );
};

export default NotFoundPage;