// src/pages/SavingsCalculatorPage.js

import React, { useState } from 'react';
import SavingsForm from '../components/SavingsForm';
import SystemInfo from '../components/SystemInfo';
import TotalSavings from '../components/TotalSavings';
import EnvironmentalImpact from '../components/EnvironmentalImpact';
import SavingsBreakdownTable from '../components/SavingsBreakdownTable';
import SavingsOverTimeGraph from '../components/SavingsOverTimeGraph';

const SavingsCalculatorPage = () => {
  const [calculationResults, setCalculationResults] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [graphData, setGraphData] = useState({});
  const [environmentalData, setEnvironmentalData] = useState({});
  const [totalSavings, setTotalSavings] = useState(null);

  const handleCalculate = (results) => {
    setCalculationResults(results);

    const {
      yearlyKWhUsage,
      rate,
      systemSizeKw,
      numberOfPanels,
      batteryCapacityKw,
      numberOfBatteries,
      solarCost,
      financingOption,
      loanTermYears,
    } = results;

    const totalYears =
      financingOption === 'ppa'
        ? 25
        : financingOption === 'loan'
        ? loanTermYears
        : 30; // For cash, consider 30 years

    const years = Array.from({ length: 30 }, (_, i) => i + 1); // 30 years

    // Calculate table data
    let cumulativeSavings = 0;

    // Pre-calculate loan monthly payment if needed
    let monthlyLoanPayment = 0;
    if (financingOption === 'loan') {
      const annualInterestRate = 0.0299;
      const monthlyInterestRate = annualInterestRate / 12;
      const numberOfPayments = loanTermYears * 12;
      monthlyLoanPayment =
        (solarCost *
          monthlyInterestRate *
          Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
        (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
    }

    const calculatedTableData = years.map((year) => {
      const monthlyUtilityBill =
        (Math.pow(1.05, year - 1) * yearlyKWhUsage * rate) / 12;
      let monthlySolarBill = 0;

      if (financingOption === 'cash') {
        // For cash, monthlySolarBill is zero
        monthlySolarBill = 0;
      } else if (financingOption === 'loan') {
        // Assign monthly payment if within loan term
        if (year <= loanTermYears) {
          monthlySolarBill = monthlyLoanPayment;
        } else {
          monthlySolarBill = 0;
        }
      } else if (financingOption === 'ppa') {
        // PPA rate is 28% cheaper than current rate
        const currentRate = rate;
        const ppaRate = currentRate * (1 - 0.28);
        if (year <= 25) {
          monthlySolarBill =
            (yearlyKWhUsage * ppaRate * Math.pow(1.03, year - 1)) / 12;
        } else {
          monthlySolarBill = 0;
        }
      }

      const monthlySavings = monthlyUtilityBill - monthlySolarBill;
      const yearlySavings = monthlySavings * 12;
      cumulativeSavings += yearlySavings;

      return {
        year,
        monthlyUtilityBill: parseFloat(monthlyUtilityBill.toFixed(2)),
        monthlySolarBill: parseFloat(monthlySolarBill.toFixed(2)),
        monthlySavings: parseFloat(monthlySavings.toFixed(2)),
        yearlySavings: parseFloat(yearlySavings.toFixed(2)),
        cumulativeSavings: parseFloat(cumulativeSavings.toFixed(2)),
      };
    });

    setTableData(calculatedTableData);

    // Calculate total savings over the contract period
    const totalSavingsAmount = calculatedTableData
      .filter((row) => row.year <= totalYears)
      .reduce((acc, row) => acc + row.yearlySavings, 0);

    setTotalSavings(totalSavingsAmount.toFixed(2));

    // Prepare graph data (monthly costs)
    const calculatedGraphData = {
      labels: calculatedTableData.map((row) => `Year ${row.year}`),
      datasets: [
        {
          label: 'Monthly Utility Bill',
          data: calculatedTableData.map((row) => row.monthlyUtilityBill),
          borderColor: 'red',
          borderWidth: 2,
          fill: false,
        },
        {
          label: 'Monthly Solar Bill',
          data: calculatedTableData.map((row) => row.monthlySolarBill),
          borderColor: 'blue',
          borderWidth: 2,
          fill: false,
        },
      ],
    };

    setGraphData(calculatedGraphData);

    // Calculate environmental impact over total years
    const totalCO2Offset = (
      (yearlyKWhUsage * totalYears * 0.5) /
      1000
    ).toFixed(2); // in tons
    const treesPlanted = Math.round(totalCO2Offset / 0.06); // Average CO2 absorption per tree per year (tons)
    const carMilesOffset = Math.round(totalCO2Offset / 0.000404); // Average CO2 emissions per mile driven (tons)

    setEnvironmentalData({
      totalCO2Offset,
      treesPlanted,
      carMilesOffset,
      totalYears,
    });
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Page Header */}
      <header className="w-full bg-white shadow-md p-4 h-16 flex items-center">
        <h1 className="text-2xl font-bold text-center text-green-600 w-full">
          Solar Savings Calculator
        </h1>
      </header>

      {/* Main Content */}
      <div className="flex-1 flex overflow-hidden">
        <div className="flex flex-1 overflow-auto flex-col md:flex-row">
          {/* Column 1: Form and System Info */}
          <div className="flex flex-col h-full w-full md:w-[20%] p-4">
            {/* Savings Form */}
            <div className="mb-4 flex-none">
              <SavingsForm onCalculate={handleCalculate} />
            </div>
            {/* System Information */}
            {calculationResults && (
              <div className="flex-1 overflow-auto">
                <SystemInfo results={calculationResults} />
              </div>
            )}
          </div>

          {/* Column 2: Total Savings, Environmental Impact, and Graph */}
          {calculationResults && (
            <div className="flex flex-col h-full w-full md:w-[45%] p-4">
              {/* Top Row: Total Savings and Environmental Impact */}
              <div className="flex flex-col md:flex-row mb-4 space-y-4 md:space-y-0 md:space-x-4">
                {/* Total Savings */}
                <div className="flex-1 flex">
                  <TotalSavings
                    totalSavings={totalSavings}
                    financingOption={calculationResults.financingOption}
                    totalYears={
                      calculationResults.financingOption === 'loan'
                        ? calculationResults.loanTermYears
                        : calculationResults.financingOption === 'ppa'
                        ? 25
                        : 30
                    }
                  />
                </div>
                {/* Environmental Impact */}
                <div className="flex-1 flex">
                  <EnvironmentalImpact data={environmentalData} />
                </div>
              </div>
              {/* Savings Over Time Graph */}
              <div className="flex-1 overflow-auto">
                <SavingsOverTimeGraph data={graphData} />
              </div>
            </div>
          )}

          {/* Column 3: Savings Breakdown Table */}
          {calculationResults && (
            <div className="w-full md:w-[35%] p-4 overflow-auto">
              <SavingsBreakdownTable data={tableData} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SavingsCalculatorPage;