// src/components/ContactSection.js

import React, { useState } from 'react';
import emailjs from '@emailjs/browser'; // Import EmailJS

const ContactSection = () => {
  // State for form inputs
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  // State for loading and success/error messages
  const [isSending, setIsSending] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Function to generate a unique ID (simple implementation)
  const generateUniqueId = () => {
    return `msg_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.name || !formData.email || !formData.message) {
      setFeedbackMessage('Please fill in all fields.');
      return;
    }

    // Generate unique ID
    const uniqueId = generateUniqueId();

    // Prepare the template parameters
    const templateParams = {
      id: uniqueId,
      name: formData.name,
      email: formData.email,
      message: formData.message,
    };

    setIsSending(true);
    setFeedbackMessage('');

    // Send email via EmailJS
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,    // Service ID
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID2,   // Template ID
        templateParams,                              // Template parameters
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY     // Public Key
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setFeedbackMessage('Your message has been sent successfully!');
          setFormData({ name: '', email: '', message: '' }); // Reset form
          setIsSending(false);
        },
        (err) => {
          console.error('FAILED...', err);
          setFeedbackMessage('An error occurred while sending your message. Please try again.');
          setIsSending(false);
        }
      );
  };

  return (
    <section id="contact" className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        {/* Section Header */}
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-800">
            Get in Touch with Us
          </h2>
          <p className="text-gray-600 mt-2">
            We'd love to hear from you. Whether you have a question about solar energy, our services, or anything else, our team is ready to answer all your questions.
          </p>
        </div>
        {/* Two-Column Layout */}
        <div className="flex flex-col md:flex-row gap-8">
          {/* Left Column: Contact Information */}
          <div className="md:w-1/2">
            <div className="bg-white p-8 rounded-lg shadow-md h-full flex flex-col">
              <h3 className="text-2xl font-semibold mb-6 text-green-700">
                Contact Information
              </h3>
              <p className="text-gray-700 mb-4">
                Feel free to reach out to us through any of the following methods.
              </p>
              <div className="space-y-4">
                <div className="flex items-center">
                  <svg
                    className="w-6 h-6 text-green-500 mr-3"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <span className="text-gray-700">info@californiasolarrelief.com</span>
                </div>
                <div className="flex items-center">
                  <svg
                    className="w-6 h-6 text-green-500 mr-3"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 10h1l1 2h16l1-2h1M5 6h14l1 4H4l1-4zM4 14v6h16v-6M9 18h6"
                    />
                  </svg>
                  <span className="text-gray-700">(916) 209-0604</span>
                </div>
                {/* You can add more contact methods here if needed */}
              </div>
            </div>
          </div>
          {/* Right Column: Contact Form */}
          <div className="md:w-1/2">
            <div className="bg-white p-8 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-6 text-green-700">
                Send Us a Message
              </h3>
              {/* Feedback Message */}
              {feedbackMessage && (
                <div
                  className={`mb-4 p-4 rounded ${
                    feedbackMessage.includes('successfully')
                      ? 'bg-green-100 text-green-700'
                      : 'bg-red-100 text-red-700'
                  }`}
                  role="alert"
                >
                  {feedbackMessage}
                </div>
              )}
              <form className="space-y-4" onSubmit={handleSubmit}>
                {/* Name */}
                <div>
                  <label className="block text-gray-700" htmlFor="name">
                    Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
                    placeholder="Your Name"
                    required
                  />
                </div>
                {/* Email */}
                <div>
                  <label className="block text-gray-700" htmlFor="email">
                    Email<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
                    placeholder="your.email@example.com"
                    required
                  />
                </div>
                {/* Message */}
                <div>
                  <label className="block text-gray-700" htmlFor="message">
                    Message<span className="text-red-500">*</span>
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500 h-32 resize-none"
                    placeholder="Your Message"
                    required
                  ></textarea>
                </div>
                {/* Submit Button */}
                <div>
                  <button
                    type="submit"
                    className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600 transition-colors"
                    disabled={isSending}
                  >
                    {isSending ? 'Sending...' : 'Send Message'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;