// src/components/SavingsBreakdownTable.js

import React from 'react';

const SavingsBreakdownTable = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <div className="bg-white shadow-md rounded p-4 w-full flex flex-col h-full">
        <h3 className="text-lg font-semibold mb-2">Savings Breakdown</h3>
        <p>No data available</p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-md rounded p-4 w-full flex flex-col h-full">
      <h3 className="text-lg font-semibold mb-2">Savings Breakdown</h3>
      <div className="flex-grow overflow-auto">
        <table className="w-full border-collapse text-xs">
          <thead>
            <tr className="border-b">
              <th className="border px-1 py-1 bg-red-200 text-center">
                Year
              </th>
              <th className="border px-1 py-1 bg-red-200 text-center">
                Monthly Utility ($)
              </th>
              <th className="border px-1 py-1 bg-blue-200 text-center">
                Monthly Solar ($)
              </th>
              <th className="border px-1 py-1 bg-green-200 text-center">
                Monthly Savings ($)
              </th>
              <th className="border px-1 py-1 bg-green-200 text-center">
                Yearly Savings ($)
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={row.year} className="hover:bg-gray-100">
                <td className="border px-1 py-1 text-center whitespace-nowrap">
                  {row.year}
                </td>
                <td className="border px-1 py-1 text-right bg-red-100 whitespace-nowrap">
                  ${parseFloat(row.monthlyUtilityBill).toFixed(2)}
                </td>
                <td className="border px-1 py-1 text-right bg-blue-100 whitespace-nowrap">
                  ${parseFloat(row.monthlySolarBill).toFixed(2)}
                </td>
                <td className="border px-1 py-1 text-right bg-green-100 whitespace-nowrap">
                  ${parseFloat(row.monthlySavings).toFixed(2)}
                </td>
                <td className="border px-1 py-1 text-right bg-green-100 whitespace-nowrap">
                  ${parseFloat(row.yearlySavings).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SavingsBreakdownTable;