// src/context/BlogContext.js

import React, { createContext, useState, useEffect } from 'react';
import importAll from '../utils/importAll'; // Utility function to import all JSON files

export const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
  const [allBlogPosts, setAllBlogPosts] = useState([]);
  const [filters, setFilters] = useState({
    categories: [],
    tags: [],
    sortOption: 'date', // 'date' or 'category'
  });

  useEffect(() => {
    const blogPosts = importAll(
      require.context('../data/blogPosts', false, /\.json$/)
    );
    console.log('Imported blog posts:', blogPosts); // Debugging

    // Ensure each blog post has a unique ID
    const uniqueBlogPosts = blogPosts.filter(
      (post, index, self) =>
        index === self.findIndex((p) => p.id === post.id)
    );

    // Sort blog posts by date descending upon initial load
    uniqueBlogPosts.sort((a, b) => new Date(b.date) - new Date(a.date));

    setAllBlogPosts(uniqueBlogPosts);
  }, []);

  return (
    <BlogContext.Provider value={{ allBlogPosts, filters, setFilters }}>
      {children}
    </BlogContext.Provider>
  );
};