// src/components/PaymentOptionsSection.js

import React from 'react';

const options = [
  {
    title: 'Ownership (Cash Purchase)',
    description:
      'Make an upfront investment to own your solar system outright, maximizing your long-term savings and increasing your property value.',
    benefits: [
      'No monthly payments',
      'Eligible for tax credits and rebates',
      'Highest return on investment',
    ],
  },
  {
    title: 'Solar Loan',
    description:
      'Finance your solar system with affordable monthly payments while still owning the system and benefiting from tax incentives.',
    benefits: [
      'Low or zero upfront cost',
      'Fixed monthly payments',
      'Ownership benefits with flexibility',
    ],
  },
  {
    title: 'Solar Subscription (PPA)',
    description:
      'Pay for the power your system generates with no upfront costs, enjoying immediate savings on your electricity bill.',
    benefits: [
      'No upfront investment',
      'Immediate savings on energy costs',
      'Maintenance handled by provider',
    ],
  },
];

const PaymentOptionsSection = () => {
  return (
    <section id="payment-options" className="py-16 bg-white">
      <div className="container mx-auto px-4">
        {/* Section Header */}
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-800">Flexible Payment Options</h2>
          <p className="text-gray-600">
            Choose the option that best fits your financial goals and start saving with solar energy.
          </p>
        </div>
        {/* Options */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {options.map((option, index) => (
            <div key={index} className="bg-gray-50 p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">{option.title}</h3>
              <p className="text-gray-600 mb-4">{option.description}</p>
              <h4 className="text-xl font-semibold mb-2">Benefits:</h4>
              <ul className="list-disc list-inside text-gray-600">
                {option.benefits.map((benefit, idx) => (
                  <li key={idx}>{benefit}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PaymentOptionsSection;