// src/pages/HomePage.js

import React from 'react';
import IntroSection from '../components/IntroSection';
import OurProcessSection from '../components/OurProcessSection';
import TechnologySection from '../components/TechnologySection';
import PaymentOptionsSection from '../components/PaymentOptionsSection';
import WhyChooseUsSection from '../components/WhyChooseUsSection';
import FAQSection from '../components/FAQSection';
import ContactSection from '../components/ContactSection';
import BlogSection from '../components/BlogSection';
import Footer from '../components/Footer';

const HomePage = () => {
  return (
    <div id="top">
      {/* Introductory Section */}
      <IntroSection />

      {/* Our Process Section */}
      <OurProcessSection />

      {/* Technology Section */}
      <TechnologySection />

      {/* Payment Options Section */}
      <PaymentOptionsSection />

      {/* Why Choose Us Section */}
      <WhyChooseUsSection />

      {/* FAQ Section */}
      <section id="faq" className="py-16 bg-gray-50">
        <div className="container mx-auto">
          <FAQSection />
        </div>
      </section>

      {/* Contact Section */}
      <ContactSection />

      {/* Blog Section */}
      <BlogSection />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default HomePage;