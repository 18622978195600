// src/pages/BlogPostsPage.js

import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { BlogContext } from '../context/BlogContext';
import DOMPurify from 'dompurify';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { motion } from 'framer-motion';
import SocialSharing from '../components/SocialSharing';
import BlogPostPreview from '../components/BlogPostPreview';
import { HashLink } from 'react-router-hash-link'; // Import HashLink

const BlogPostsPage = () => {
  const { categoryId, postId } = useParams();
  const { allBlogPosts, filters } = useContext(BlogContext);
  const [blogPosts, setBlogPosts] = useState([]);
  const [singlePost, setSinglePost] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (postId) {
      const foundPost = allBlogPosts.find((p) => p.id === parseInt(postId));
      setSinglePost(foundPost);
    } else {
      let filteredPosts = [...allBlogPosts]; // Clone to avoid mutating original array

      if (categoryId) {
        filteredPosts = filteredPosts.filter(
          (post) => post.category === categoryId
        );
      }

      if (filters && filters.categories.length > 0) {
        filteredPosts = filteredPosts.filter((post) =>
          filters.categories.includes(post.category)
        );
      }
      if (filters && filters.tags.length > 0) {
        filteredPosts = filteredPosts.filter((post) =>
          post.tags.some((tag) => filters.tags.includes(tag))
        );
      }

      if (filters && filters.sortOption === 'date') {
        filteredPosts.sort((a, b) => new Date(b.date) - new Date(a.date));
      } else if (filters && filters.sortOption === 'category') {
        filteredPosts.sort((a, b) => a.category.localeCompare(b.category));
      }

      setBlogPosts(filteredPosts);
      setSinglePost(null);
    }
  }, [categoryId, postId, filters, allBlogPosts]);

  if (postId) {
    if (!singlePost) {
      return (
        <HelmetProvider>
          <Helmet>
            <title>Post Not Found | California Solar Relief</title>
            <meta name="description" content="The requested blog post was not found." />
          </Helmet>
          <div className="container mx-auto py-16 px-4 pt-20">
            <h2 className="text-3xl font-bold text-center">Post Not Found</h2>
            <div className="text-center mt-4">
              <Link to="/blog" className="text-blue-600 hover:underline">
                &larr; Back to Blog
              </Link>
            </div>
          </div>
        </HelmetProvider>
      );
    }

    // Sanitize the HTML content
    const sanitizedContent = DOMPurify.sanitize(singlePost.content);

    // Extract additional fields if needed, e.g., videos
    const { videos } = singlePost; // Ensure your JSON includes a 'videos' array

    return (
      <HelmetProvider>
        <Helmet>
          <title>{singlePost.title} | California Solar Relief</title>
          <meta name="description" content={singlePost.excerpt} />
          <meta property="og:title" content={singlePost.title} />
          <meta property="og:description" content={singlePost.excerpt} />
          <meta property="og:image" content={singlePost.image} />
          {/* Structured Data */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "headline": singlePost.title,
              "image": `https://californiasolarrelief.com${singlePost.image}`,
              "author": {
                "@type": "Person",
                "name": "Your Name" // Replace with actual author name
              },
              "publisher": {
                "@type": "Organization",
                "name": "California Solar Relief",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://californiasolarrelief.com/images/FullLogo_Transparent.png"
                }
              },
              "datePublished": singlePost.date,
              "description": singlePost.excerpt
            })}
          </script>
        </Helmet>
        <div className="container mx-auto py-16 px-4 pt-20">
          {/* Back to Blog Link */}
          <Link
            to="/blog"
            className="text-blue-600 hover:underline mb-8 inline-block"
          >
            &larr; Back to Blog
          </Link>

          {/* Blog Post Content */}
          <article>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="mb-8"
            >
              <img
                src={singlePost.image}
                alt={singlePost.title}
                className="w-full h-64 object-cover rounded shadow-md"
                loading="lazy"
              />
            </motion.div>
            <motion.h1
              className="text-4xl font-bold mb-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              {singlePost.title}
            </motion.h1>
            <motion.p
              className="text-gray-600 mb-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              {new Date(singlePost.date).toLocaleDateString()}
            </motion.p>
            {/* Interactive Content: Intro and Video Side by Side */}
            <div className="flex flex-col md:flex-row items-start md:space-x-8">
              {/* Introductory Content */}
              <div className="md:w-1/2">
                <div
                  className="prose max-w-none"
                  dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                ></div>
              </div>
              {/* Embedded Video */}
              <div className="md:w-1/2 mt-8 md:mt-0">
                {videos && videos.length > 0 && (
                  <div className="flex justify-center">
                    <div className="w-full max-w-md">
                      <iframe
                        src={videos[0]}
                        title="Embedded Video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className="w-full h-48 sm:h-60 md:h-72 rounded shadow-md"
                      ></iframe>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Social Sharing */}
            <SocialSharing
              url={`https://californiasolarrelief.com/blog/post/${singlePost.id}`}
              title={singlePost.title}
            />
            {/* Call to Action */}
            <div className="mt-8">
              <p className="font-bold">Ready to take the next step?</p>
              <HashLink
                smooth
                to="/#top" // Updated link to navigate to the top of the homepage
                className="cta-button inline-block bg-green-500 text-white px-6 py-3 rounded-full hover:bg-green-600 transition-colors"
              >
                Get a personalized solar savings quote now!
              </HashLink>
            </div>
            {/* Related Posts */}
            <div className="mt-12">
              <h2 className="text-2xl font-semibold mb-4">Related Posts</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {allBlogPosts
                  .filter(
                    (post) =>
                      post.id !== singlePost.id && post.category === singlePost.category
                  )
                  .slice(0, 3)
                  .map((post) => (
                    <motion.div
                      key={post.id}
                      className="bg-white rounded shadow-md overflow-hidden hover:shadow-lg transition-shadow cursor-pointer"
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.5 }}
                      onClick={() => navigate(`/blog/post/${post.id}`)}
                    >
                      <img
                        src={post.image}
                        alt={post.title}
                        className="w-full h-32 object-cover"
                        loading="lazy"
                      />
                      <div className="p-4">
                        <h3 className="text-xl font-semibold mb-2">{post.title}</h3>
                        <p className="text-blue-600 hover:underline">
                          Read More
                        </p>
                      </div>
                    </motion.div>
                  ))}
              </div>
            </div>
            {/* Back to Blog */}
            <div className="mt-8">
              <Link to="/blog" className="text-blue-600 hover:underline">
                &larr; Back to Blog
              </Link>
            </div>
          </article>
        </div>
      </HelmetProvider>
    );
  } else {
    // Render the list of blog posts
    return (
      <HelmetProvider>
        <Helmet>
          <title>
            {categoryId
              ? `Posts in ${categoryId} | California Solar Relief`
              : 'All Blog Posts | California Solar Relief'}
          </title>
          <meta
            name="description"
            content={
              categoryId
                ? `Explore our latest blog posts in the ${categoryId} category.`
                : 'Explore our comprehensive blog posts on solar energy and sustainability.'
            }
          />
          {/* Open Graph tags can also be added here if needed */}
        </Helmet>
        <section id="blog-posts" className="py-16 bg-gray-50 pt-20">
          <div className="container mx-auto">
            {/* Header */}
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold text-gray-800">
                {categoryId ? `Posts in ${categoryId}` : 'All Blog Posts'}
              </h2>
            </div>
            {/* Blog Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {blogPosts.map((post) => (
                <motion.div
                  key={post.id}
                  className="bg-white shadow rounded overflow-hidden hover:shadow-lg transition-shadow cursor-pointer"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  onClick={() => navigate(`/blog/post/${post.id}`)}
                >
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-48 object-cover rounded-t"
                    loading="lazy"
                  />
                  <div className="p-4">
                    <h3 className="text-xl font-semibold mb-2">{post.title}</h3>
                    <p className="text-gray-600 mb-4">{post.excerpt}</p>
                    <p className="text-blue-600 hover:underline">
                      Read More
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
            {/* Call to Action */}
            <div className="text-center mt-12">
              <HashLink
                smooth
                to="/#top" // Updated link to navigate to the top of the homepage
                className="cta-button inline-block bg-green-500 text-white px-6 py-3 rounded-full hover:bg-green-600 transition-colors"
              >
                Get a personalized solar savings quote now!
              </HashLink>
            </div>
            {/* Pagination or Load More Button */}
            {/* Add pagination if needed */}
          </div>
        </section>
      </HelmetProvider>
    );
  }
};

export default BlogPostsPage;