// src/components/SystemInfo.js

import React from 'react';
import { FaSolarPanel, FaBatteryFull } from 'react-icons/fa'; // Importing Font Awesome icons

const SystemInfo = ({ results }) => {
  if (!results) {
    return null;
  }

  const {
    systemSizeKw,
    numberOfPanels,
    batteryCapacityKw,
    numberOfBatteries,
    solarCost,
  } = results;

  // Calculate costs
  const panelsCost = systemSizeKw * 1000 * 4; // $4 per Watt
  const batteriesCost = numberOfBatteries * 6000; // $6,000 per battery
  const totalSystemCost = panelsCost + batteriesCost;

  return (
    <div className="bg-white shadow-md rounded p-4 w-full h-full flex flex-col justify-between">
      <div>
        <h3 className="text-lg font-semibold mb-4">System Information</h3>
        <div>
          <h4 className="font-semibold mb-2">Current System</h4>
          <ul className="space-y-2">
            <li>
              <strong>System Size:</strong> {systemSizeKw} kW
            </li>
            <li>
              <strong>Number of Panels:</strong> {numberOfPanels}
            </li>
            <li>
              <strong>Battery Capacity:</strong> {batteryCapacityKw} kW
            </li>
            <li>
              <strong>Number of Batteries:</strong> {numberOfBatteries}
            </li>
          </ul>
        </div>
        <div className="mt-4">
          <h4 className="font-semibold mb-2">System Pricing</h4>
          <ul className="space-y-2">
            <li>
              <strong>Panels Cost:</strong> ${panelsCost.toLocaleString()}
            </li>
            <li>
              <strong>Batteries Cost:</strong> ${batteriesCost.toLocaleString()}
            </li>
            <li>
              <strong>Total System Cost:</strong> ${totalSystemCost.toLocaleString()}
            </li>
          </ul>
        </div>
      </div>

      {/* Icons Section */}
      <div className="mt-6 flex justify-center space-x-6">
        {/* Solar Panel Icon */}
        <div className="flex flex-col items-center">
          <FaSolarPanel className="text-yellow-500 text-4xl" />
          <span className="mt-2 text-sm">Solar Panels</span>
        </div>

        {/* Battery Icon */}
        <div className="flex flex-col items-center">
          <FaBatteryFull className="text-green-500 text-4xl" />
          <span className="mt-2 text-sm">Batteries</span>
        </div>
      </div>
    </div>
  );
};

export default SystemInfo;