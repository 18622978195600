// src/components/EnvironmentalImpact.js

import React from 'react';
import { FaCarSide, FaTree } from 'react-icons/fa';

const EnvironmentalImpact = ({ data }) => {
  if (!data) {
    return (
      <div className="bg-white shadow-md rounded p-4 w-full flex flex-col h-full">
        <h3 className="text-lg font-semibold mb-4">Environmental Impact</h3>
        <p>No data available</p>
      </div>
    );
  }

  const { totalCO2Offset, treesPlanted, carMilesOffset, totalYears } = data;

  return (
    <div className="bg-white shadow-md rounded p-4 w-full flex flex-col h-full">
      <h3 className="text-lg font-semibold mb-4">
        Environmental Impact Over {totalYears} Years
      </h3>
      <div className="flex items-center space-x-4 mb-4">
        <FaCarSide size={40} className="text-blue-500" />
        <p>
          Driving equivalent:{' '}
          <strong>{carMilesOffset.toLocaleString()} miles</strong>
        </p>
      </div>
      <div className="flex items-center space-x-4">
        <FaTree size={40} className="text-green-600" />
        <p>
          Trees planted: <strong>{treesPlanted.toLocaleString()}</strong>
        </p>
      </div>
    </div>
  );
};

export default EnvironmentalImpact;