// src/components/IntroSection.js

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser'; // Import EmailJS

const IntroSection = () => {
  // State for DatePicker and Loading
  const [selectedDate, setSelectedDate] = useState(null);
  const [isSending, setIsSending] = useState(false);

  // React Hook Form setup
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Handle form submission
  const onSubmit = (data) => {
    if (!selectedDate) {
      alert('Please select a date and time for the appointment.');
      return;
    }

    // Combine form data with selectedDate
    const formData = { ...data, appointment: selectedDate };

    // Prepare the data for EmailJS
    const templateParams = {
      name: formData.name,
      address: formData.address,
      email: formData.email,
      phone: formData.phone,
      electricityBill: formData.electricityBill,
      appointment: formData.appointment
        ? formData.appointment.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })
        : '',
    };

    setIsSending(true); // Start loading

    // Send the email via EmailJS
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Service ID from .env
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Template ID from .env
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY // Public Key from .env
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          // Reset form after successful submission
          reset();
          setSelectedDate(null);
          setIsSending(false); // Stop loading
          alert('Thank you! Your expert will be in contact shortly.');
        },
        (err) => {
          console.error('FAILED...', err);
          setIsSending(false); // Stop loading
          alert('An error occurred while sending your request. Please try again.');
        }
      );
  };

  return (
    <section
      id="home" 
      className="pt-16 min-h-screen bg-cover bg-center relative"
      style={{ backgroundImage: 'url("/pexels-pixabay-356036.jpg")' }}
    >
      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black opacity-60 z-0"></div>

      {/* Content Container */}
      <div className="relative z-10 container mx-auto px-4 flex items-center min-h-screen">
        <div className="flex flex-col md:flex-row gap-8 w-full">
          {/* Left Column - Introductory Information and CTA */}
          <div className="md:w-2/3 flex flex-col items-center justify-center">
            {/* Introductory Information */}
            <div className="text-center">
              <h1 className="text-white text-5xl font-bold mb-4">
                California Solar Relief
              </h1>
              <p className="text-white text-3xl mb-6">
                Helping homeowners achieve energy independence one sunny day at a time.
              </p>
              <div className="flex flex-col space-y-4 justify-center items-center">
                {/* Learn More About Us Button */}
                <a
                  href="#our-process"
                  className="bg-green-500 text-white text-lg px-6 py-3 rounded hover:bg-green-600 transition-colors w-auto mx-auto"
                >
                  Learn More About Us
                </a>

                {/* New Header */}
                <h2 className="text-white text-xl font-semibold">
                  Try Our Free Solar Savings Calculator to get an understanding of what solar can do for you!
                </h2>

                {/* Solar Calculator Button */}
                <Link
                  to="/calculator"
                  className="bg-blue-500 text-white text-lg px-6 py-3 rounded hover:bg-blue-600 transition-colors font-semibold shadow-lg w-auto mx-auto"
                >
                  Solar Calculator
                </Link>
              </div>
            </div>
          </div>

          {/* Right Column - Solar Savings Express Form */}
          <div className="md:w-1/3 flex items-center justify-center">
            <div className="bg-white bg-opacity-90 p-6 rounded-lg shadow-md flex flex-col w-full">
              <h2 className="text-2xl font-semibold mb-4 text-green-700 text-center">
                Get information about your personal process today!
              </h2>
              {/* Added Explanation */}
              <p className="text-gray-700 text-center mb-4">
                Your solar expert will connect with you to answer any questions
              </p>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col flex-grow space-y-4"
              >
                {/* Name */}
                <div>
                  <label className="block text-gray-700">
                    Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="name"
                    type="text"
                    {...register('name', { required: 'Name is required' })}
                    className={`w-full px-3 py-2 border rounded ${
                      errors.name ? 'border-red-500' : 'border-gray-300'
                    } focus:outline-none focus:ring-2 focus:ring-green-500`}
                    placeholder="Your Full Name"
                  />
                  {errors.name && (
                    <p className="text-red-500 text-sm mt-1" role="alert">
                      {errors.name.message}
                    </p>
                  )}
                </div>

                {/* Address */}
                <div>
                  <label className="block text-gray-700">
                    Address<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="address"
                    type="text"
                    {...register('address', { required: 'Address is required' })}
                    className={`w-full px-3 py-2 border rounded ${
                      errors.address ? 'border-red-500' : 'border-gray-300'
                    } focus:outline-none focus:ring-2 focus:ring-green-500`}
                    placeholder="Your Address"
                  />
                  {errors.address && (
                    <p className="text-red-500 text-sm mt-1" role="alert">
                      {errors.address.message}
                    </p>
                  )}
                </div>

                {/* Email */}
                <div>
                  <label className="block text-gray-700">
                    Email<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="email"
                    type="email"
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/,
                        message: 'Invalid email address',
                      },
                    })}
                    className={`w-full px-3 py-2 border rounded ${
                      errors.email ? 'border-red-500' : 'border-gray-300'
                    } focus:outline-none focus:ring-2 focus:ring-green-500`}
                    placeholder="your.email@example.com"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm mt-1" role="alert">
                      {errors.email.message}
                    </p>
                  )}
                </div>

                {/* Phone Number */}
                <div>
                  <label className="block text-gray-700">
                    Phone Number<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="phone"
                    type="tel"
                    {...register('phone', {
                      required: 'Phone number is required',
                      pattern: {
                        value: /^\+?[1-9]\d{1,14}$/,
                        message: 'Invalid phone number',
                      },
                    })}
                    className={`w-full px-3 py-2 border rounded ${
                      errors.phone ? 'border-red-500' : 'border-gray-300'
                    } focus:outline-none focus:ring-2 focus:ring-green-500`}
                    placeholder="+1 (555) 123-4567"
                  />
                  {errors.phone && (
                    <p className="text-red-500 text-sm mt-1" role="alert">
                      {errors.phone.message}
                    </p>
                  )}
                </div>

                {/* Current Electricity Bill Cost */}
                <div>
                  <label className="block text-gray-700">
                    Current Electricity Bill ($)<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="electricityBill"
                    type="number"
                    step="0.01"
                    {...register('electricityBill', {
                      required: 'Electricity bill cost is required',
                      min: { value: 0, message: 'Bill cost cannot be negative' },
                    })}
                    className={`w-full px-3 py-2 border rounded ${
                      errors.electricityBill ? 'border-red-500' : 'border-gray-300'
                    } focus:outline-none focus:ring-2 focus:ring-green-500`}
                    placeholder="e.g., 150.00"
                  />
                  {errors.electricityBill && (
                    <p className="text-red-500 text-sm mt-1" role="alert">
                      {errors.electricityBill.message}
                    </p>
                  )}
                </div>

                {/* Schedule a Phone Call */}
                <div>
                  <label className="block text-gray-700">
                    When is a good time to connect?
                    <span className="text-red-500">*</span>
                  </label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    showTimeSelect
                    timeFormat="h:mm aa"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    placeholderText="Select Date and Time"
                    className={`w-full px-3 py-2 border rounded ${
                      !selectedDate ? 'border-gray-300' : 'border-gray-300'
                    } focus:outline-none focus:ring-2 focus:ring-green-500`}
                  />
                  {!selectedDate && (
                    <p className="text-red-500 text-sm mt-1" role="alert">
                      Please select a date and time.
                    </p>
                  )}
                </div>

                {/* Submit Button */}
                <div>
                  <button
                    type="submit"
                    className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600 transition-colors"
                    disabled={isSending || !selectedDate}
                  >
                    {isSending ? 'Sending...' : 'Get Your Savings Report'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;