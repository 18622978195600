// src/components/NavigationBar.js

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { FaBars } from 'react-icons/fa';
import SortAndFilterMenu from './SortAndFilterMenu'; // Ensure this path is correct

const NavigationBar = ({ darkMode, setDarkMode }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  // Determine if the current path is the blog page
  const isBlogPage = location.pathname.startsWith('/blog');

  return (
    <nav className="fixed top-0 w-full bg-white dark:bg-gray-800 shadow z-50">
      <div className="container mx-auto flex items-center justify-between p-4">
        {/* Left: Company Logo */}
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            {/* Reference the logo using the public URL */}
            <img
              src="/images/FullLogo_Transparent.png"
              alt="California Solar Relief Logo"
              className="h-8 w-auto mr-2"
            />
            {/* Optional: Company Name beside the logo */}
            <span className="text-2xl font-bold text-green-600 dark:text-green-400 hidden sm:inline">
              California Solar Relief
            </span>
          </Link>
        </div>

        {/* Right: Navigation Links and Menu Button */}
        <div className="flex items-center space-x-4">
          {/* Conditionally render the menu button on the blog page */}
          {isBlogPage && (
            <button
              className="md:hidden focus:outline-none"
              onClick={() => setMenuOpen(true)}
              aria-label="Open Menu"
            >
              <FaBars size={24} />
            </button>
          )}

          {/* Navigation Links (Hidden on Mobile if Menu Button is Present) */}
          <div className="hidden md:flex space-x-4">
            <Link
              to="/"
              className="hover:underline text-gray-800 dark:text-gray-200"
            >
              Home
            </Link>
            <Link
              to="/blog"
              className="hover:underline text-gray-800 dark:text-gray-200"
            >
              Blog
            </Link>
            <Link
              to="/calculator"
              className="hover:underline text-gray-800 dark:text-gray-200"
            >
              Savings Calculator
            </Link>
            <HashLink
              smooth
              to="/#contact"
              className="hover:underline text-gray-800 dark:text-gray-200"
            >
              Contact
            </HashLink>
          </div>
        </div>
      </div>
      {/* Sort and Filter Menu */}
      {menuOpen && (
        <SortAndFilterMenu closeMenu={() => setMenuOpen(false)} />
      )}
    </nav>
  );
};

export default NavigationBar;