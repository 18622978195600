import React from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

const BlogPostPreview = ({ post }) => {
  const navigate = useNavigate();

  // Sanitize the excerpt to prevent XSS attacks
  const sanitizedExcerpt = DOMPurify.sanitize(post.excerpt);

  return (
    <div
      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-xl transition-shadow"
      onClick={() => navigate(`/blog/post/${post.id}`)}
    >
      <img
        src={post.image}
        alt={post.title}
        className="w-full h-48 object-cover"
        loading="lazy"
      />
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-2">{post.title}</h3>
        <div
          className="text-gray-700 mb-4"
          dangerouslySetInnerHTML={{ __html: sanitizedExcerpt }}
        ></div>
        <button className="text-blue-600 hover:underline">
          Read More &rarr;
        </button>
      </div>
    </div>
  );
};

export default BlogPostPreview;