// src/components/OurProcessSection.js

import React from 'react';
import { FaSatelliteDish, FaSolarPanel, FaBatteryFull, FaDollarSign } from 'react-icons/fa';

const steps = [
  {
    title: 'Satellite Scans & AI Modeling',
    description:
      'We use advanced satellite imagery and AI models to precisely calculate the potential solar energy production of your rooftop.',
    icon: <FaSatelliteDish size={40} className="text-green-500" />,
  },
  {
    title: 'Custom Engineered Solar Design',
    description:
      'Our engineers design a solar system tailored to cover all your electrical usage, aiming to eliminate your PG&E bill.',
    icon: <FaSolarPanel size={40} className="text-green-500" />,
  },
  {
    title: 'Backup Battery Installation',
    description:
      'We install backup batteries to store excess energy produced during the day, ensuring you have power at night when the panels aren’t producing.',
    icon: <FaBatteryFull size={40} className="text-green-500" />,
  },
  {
    title: 'Zero Out-of-Pocket Cost',
    description:
      'All of this is provided at no upfront cost to you, making the switch to solar seamless and affordable.',
    icon: <FaDollarSign size={40} className="text-green-500" />,
  },
];

const OurProcessSection = () => {
  return (
    <section id="our-process" className="py-16 bg-white">
      <div className="container mx-auto px-4">
        {/* Section Header */}
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-800">Our Process</h2>
          <p className="text-gray-600">
            Discover how we help you lower your monthly bills and achieve energy independence.
          </p>
        </div>
        {/* Process Steps */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <div className="mb-4">{step.icon}</div>
              <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurProcessSection;