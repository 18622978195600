// src/pages/TermsOfService.js
import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>

      <p>
        <strong>Effective Date:</strong> [11/30/2024]
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">1. Acceptance of Terms</h2>
      <p>
        By accessing or using our website, you agree to comply with and be bound by
        these Terms of Service. If you do not agree to these terms, please do not use
        our website.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">2. Use of Our Website</h2>
      <p>
        You agree to use our website only for lawful purposes. You may not use our
        website in any way that:
      </p>
      <ul className="list-disc list-inside">
        <li>Violates any applicable laws or regulations</li>
        <li>Infringes on the rights of others</li>
        <li>Disrupts the website’s functionality</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">3. Limitation of Liability</h2>
      <p>
        We are not liable for any damages that arise from your use of our website. We
        provide our website "as is" without warranties of any kind.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">4. Changes to Terms</h2>
      <p>
        We may update these Terms of Service from time to time. Your continued use of
        our website after any changes constitutes your acceptance of the new terms.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">5. Contact Us</h2>
      <p>
        If you have any questions about these Terms of Service, please contact us at
        info@californiasolarrelief.com.
      </p>
    </div>
  );
};

export default TermsOfService;
