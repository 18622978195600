import React from 'react';

const TechnologySection = () => {
  return (
    <section
      id="technology"
      className="relative py-16"
      style={{
        backgroundImage: "url('/images/pexels-brunoscramgnon-585759.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Overlay that spans the entire background */}
      <div
        className="absolute inset-0"
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.05)', // Semi-transparent overlay
        }}
      ></div>

      {/* Content container */}
      <div className="relative container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Column */}
        <div className="p-6">
          {/* Section Header */}
          <div className="text-white mb-8">
            <h2 className="text-3xl font-bold">Our Technology</h2>
            <p>
              Discover the high-performance solar panels and batteries we use
              to ensure long-lasting energy solutions.
            </p>
          </div>

          {/* Combined Product Information */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">
              High-Efficiency Solar Panels & Advanced Battery Storage
            </h3>
            <ul className="list-disc list-inside mb-4 text-gray-600">
              <li>Solar Panel Efficiency: 22%</li>
              <li>Battery Capacity: 5 kWh</li>
              <li>Solar Panel Warranty: 25 Years</li>
              <li>
                Battery Warranty: 10 Years (25 years with Power Purchase
                Agreement)
              </li>
              <li>Weather-resistant solar panel design</li>
              <li>Seamless battery integration with solar systems</li>
              <li>Reliable power storage for night or outages</li>
            </ul>
            <p className="text-gray-600">
              Our solar panels and battery systems work together to maximize
              energy production and provide a reliable power backup solution.
              Compatible with generators for extended outages, ensuring your
              energy needs are always met.
            </p>
          </div>
        </div>

        {/* Right Column - Empty Space for Future Content or Design */}
        <div></div>
      </div>
    </section>
  );
};

export default TechnologySection;