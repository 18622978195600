// src/components/SortAndFilterMenu.js

import React from 'react';
import { motion } from 'framer-motion';

const SortAndFilterMenu = ({ closeMenu }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="absolute top-16 left-0 w-full bg-white dark:bg-gray-800 shadow-lg z-40 p-4"
    >
      {/* Implement your sort and filter options here */}
      <button
        onClick={closeMenu}
        className="text-red-500 hover:text-red-700 focus:outline-none"
      >
        Close
      </button>
      <div className="mt-4">
        <h3 className="text-lg font-semibold">Sort By</h3>
        {/* Sort options */}
      </div>
      <div className="mt-4">
        <h3 className="text-lg font-semibold">Filter By</h3>
        {/* Filter options */}
      </div>
    </motion.div>
  );
};

export default SortAndFilterMenu;