// src/components/FAQSection.js

import React, { useState } from 'react';

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "Isn't switching to solar expensive? I can't afford a huge upfront payment.",
      answer:
        "Switching to solar with California Solar Relief has zero upfront costs. Our program covers installation for free, and you won’t need to pay anything out of pocket. Instead of a high PG&E bill, you’ll have a much lower, fixed solar bill, allowing you to save money from day one.",
    },
    {
      question: "How reliable are solar panels and what if they get damaged?",
      answer:
        "Our solar panels come with a comprehensive 25-year warranty that covers maintenance and repairs. They are built to withstand extreme weather conditions, ensuring reliability. If there’s ever an issue, our team is just a call away for quick support.",
    },
    {
      question: "Will solar panels work during cloudy days or at night?",
      answer:
        "Solar panels work efficiently even on cloudy days by capturing diffuse sunlight. At night, your system uses stored energy from your backup batteries or draws from the grid. With our tailored solutions, you’ll always have the power you need.",
    },
    {
      question: "How much will I actually save, and what if my savings aren't as high as promised?",
      answer:
        "With California Solar Relief, your savings are guaranteed. We assess your home’s energy usage and design a custom system that ensures you save from day one. On average, homeowners save 20-30% on their electricity bills annually.",
    },
    {
      question: "What happens if I sell my house after installing solar panels?",
      answer:
        "Solar panels increase your home’s value and appeal to potential buyers. If you decide to sell, the solar system can be transferred to the new owner seamlessly, or you can pay off the system early to boost resale value.",
    },
    {
      question: "Are there any government incentives or tax credits available?",
      answer:
        "Yes, there are federal and state incentives that can significantly reduce the cost of your solar system. Our team will help you navigate and maximize these benefits.",
    },
    {
      question: "Do I need to get approval from my HOA or local government?",
      answer:
        "We handle all the necessary permits and approvals, ensuring compliance with local regulations and HOA guidelines.",
    },
    {
      question: "How long does the installation process take?",
      answer:
        "The entire process, from initial consultation to installation, typically takes 4-8 weeks. We'll keep you informed every step of the way.",
    },
    {
      question: "Will solar panels damage my roof or void my roof warranty?",
      answer:
        "Our professional installers ensure that your roof remains intact. In many cases, solar panels can actually protect your roof from the elements. We work with you to maintain any existing roof warranties.",
    },
    {
      question: "What if my energy needs change in the future?",
      answer:
        "Our systems are scalable. If your energy needs increase, we can evaluate and expand your system to accommodate the additional usage.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bg-white shadow-md p-6 rounded transition-opacity duration-1000">
      <h2 className="text-2xl font-bold mb-4 text-center">Frequently Asked Questions</h2>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="border rounded p-4 cursor-pointer hover:bg-gray-100"
            onClick={() => toggleFAQ(index)}
          >
            <h3 className="text-lg font-semibold flex justify-between items-center">
              {faq.question}
              <span
                className={`transform transition-transform ${
                  activeIndex === index ? 'rotate-180' : ''
                }`}
              >
                ▼
              </span>
            </h3>
            {activeIndex === index && (
              <p className="text-gray-600 mt-2">{faq.answer}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;