// src/components/BlogSection.js

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import BlogPostPreview from './BlogPostPreview';
import { BlogContext } from '../context/BlogContext';

const BlogSection = () => {
  const { allBlogPosts } = useContext(BlogContext);

  if (!allBlogPosts || allBlogPosts.length === 0) {
    return null; // or a loading spinner
  }

  // Sort blog posts by date descending without mutating original array
  const sortedBlogPosts = [...allBlogPosts].sort((a, b) => new Date(b.date) - new Date(a.date));

  // Get the latest 3 blog posts
  const latestBlogs = sortedBlogPosts.slice(0, 3);

  return (
    <section id="blog" className="py-16 bg-gray-50">
      <div className="container mx-auto">
        {/* Hero for Blog Section */}
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-800">Explore Our Blog</h2>
          <p className="text-gray-600">
            Discover insights and tips on solar energy and sustainability.
          </p>
        </div>
        {/* Latest Blog Posts */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {latestBlogs.map((post) => (
            <BlogPostPreview key={post.id} post={post} />
          ))}
        </div>
        {/* See All Blog Posts Button */}
        <div className="text-center mt-8">
          <Link to="/blog">
            <button className="px-6 py-3 bg-green-500 text-white rounded hover:bg-green-600">
              See All Blog Posts
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;