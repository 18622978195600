// src/components/WhyChooseUsSection.js

import React from 'react';
import { FaHandshake, FaTools, FaMedal, FaChartLine } from 'react-icons/fa';

const reasons = [
  {
    title: 'Expert Installers',
    description:
      'We connect you with the best local installers to ensure quality workmanship and optimal system performance.',
    icon: <FaTools size={40} className="text-green-500" />,
  },
  {
    title: 'Best Rates & Savings',
    description:
      'Our extensive network allows us to secure the most competitive rates, maximizing your monthly savings.',
    icon: <FaChartLine size={40} className="text-green-500" />,
  },
  {
    title: 'Veteran-Owned & Operated',
    description:
      'As a veteran-owned company, we bring discipline, integrity, and commitment to every project.',
    icon: <FaMedal size={40} className="text-green-500" />,
  },
  {
    title: 'Engineering & Data-Driven Solutions',
    description:
      'We leverage cutting-edge technology to design systems tailored to your unique energy needs.',
    icon: <FaHandshake size={40} className="text-green-500" />,
  },
];

const WhyChooseUsSection = () => {
  return (
    <section id="why-choose-us" className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        {/* Section Header */}
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-800">Why Choose California Solar Relief</h2>
          <p className="text-gray-600">
            Empowering homeowners to achieve energy independence with trusted expertise.
          </p>
        </div>
        {/* Reasons */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {reasons.map((reason, index) => (
            <div key={index} className="flex">
              <div className="mr-4">{reason.icon}</div>
              <div>
                <h3 className="text-2xl font-semibold mb-2">{reason.title}</h3>
                <p className="text-gray-600">{reason.description}</p>
              </div>
            </div>
          ))}
        </div>
        {/* Closing Statement */}
        <div className="mt-12 text-center">
          <p className="text-xl text-gray-700">
            Escape your utility monopoly and inflated prices. Join us in creating a sustainable and affordable energy future.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsSection;