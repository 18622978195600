// src/pages/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

      <p>
        <strong>Effective Date:</strong> [11/30/2024]
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">1. Introduction</h2>
      <p>
        Welcome to California Solar Relief ("we," "us," "our"). We are committed to
        protecting your privacy and ensuring the security of your personal
        information. This Privacy Policy outlines how we collect, use, disclose, and
        safeguard your information when you visit our website.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">2. Information We Collect</h2>
      <p>
        We collect personal information that you provide directly to us, such as when
        you fill out a form on our website. This information includes:
      </p>
      <ul className="list-disc list-inside">
        <li>Name</li>
        <li>Email Address</li>
        <li>Phone Number</li>
        <li>Message Content</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">3. How We Use Your Information</h2>
      <p>
        We use the information we collect for the following purposes:
      </p>
      <ul className="list-disc list-inside">
        <li>To respond to inquiries and provide customer support</li>
        <li>To improve our website and services</li>
        <li>To send promotional communications</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">4. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at
        info@californiasolarrelief.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;