// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import { BlogProvider } from './context/BlogContext';
import HomePage from './pages/HomePage';
import BlogPostsPage from './pages/BlogPostsPage';
import SavingsCalculatorPage from './pages/SavingsCalculatorPage';
import FloatingCTA from './components/FloatingCTA'; // Optional: If implemented
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import NotFoundPage from './pages/NotFoundPage'; // Import NotFoundPage
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';


function App() {
  const [darkMode, setDarkMode] = useState(false);

  // Persist dark mode preference
  useEffect(() => {
    const storedMode = localStorage.getItem('darkMode');
    if (storedMode) {
      setDarkMode(JSON.parse(storedMode));
    }
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('darkMode', true);
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('darkMode', false);
    }
  }, [darkMode]);

  return (
    <HelmetProvider>
      <BlogProvider>
        <Router>
          <NavigationBar darkMode={darkMode} setDarkMode={setDarkMode} />
          <FloatingCTA /> {/* Optional: Add FloatingCTA here */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            {/* Route for all blog posts */}
            <Route path="/blog" element={<BlogPostsPage />} />
            {/* Route for single blog post */}
            <Route path="/blog/post/:postId" element={<BlogPostsPage />} />
            {/* Route for blog category (if implemented) */}
            <Route path="/blog/:categoryId" element={<BlogPostsPage />} />
            {/* Route for savings calculator */}
            <Route path="/calculator" element={<SavingsCalculatorPage />} />
            {/* Catch-all Route for 404 */}
            <Route path="*" element={<NotFoundPage />} />
            {/* Add other routes as needed */}

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </Routes>
        </Router>
      </BlogProvider>
    </HelmetProvider>
  );
}

export default App;