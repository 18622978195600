// src/components/TotalSavings.js

import React from 'react';

const TotalSavings = ({ totalSavings, financingOption, totalYears }) => {
  let message = '';

  if (financingOption === 'cash') {
    message = `Total Savings over ${totalYears} years compared to utility costs without solar: `;
  } else if (financingOption === 'loan') {
    message = `Total Savings over ${totalYears} years with loan financing: `;
  } else if (financingOption === 'ppa') {
    message = `Total Savings over 25 years with PPA: `;
  }

  return (
    <div className="bg-white shadow-md p-4 w-full h-full flex flex-col">
      <h3 className="text-lg font-semibold mb-2 text-center">Total Savings</h3>
      <p className="text-base text-center">
        {message}
        <span className="text-green-600 text-3xl flex flex-col justify-center items-center font-bold">
          ${parseFloat(totalSavings).toLocaleString()}
        </span>
      </p>
    </div>
  );
};

export default TotalSavings;